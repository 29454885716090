import DataTable from "react-data-table-component";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import MilerButton from "components/milerButton/MilerButton";
import { hasPermission } from "utils/hasPermission";

const CustomHeader = ({ name, email }) => (
  <div
    style={{
      marginTop: "4px",
      display: "flex",
      flexDirection: "column",
      gap: "0.3rem",
    }}
  >
    <div>{name}</div>
    <div style={{ fontSize: "10px", color: "#626365" }}>{email}</div>
  </div>
);

const ManageLogin = ({ data, handlePasswordReset, reInvite, loading }) => {
  const columns = [
    {
      selector: (row) => row.username,
      cell: (row) => {
        const name = `${row?.given_name} ${row?.family_name}`;
        return CellBuilder(<CustomHeader name={name} email={row?.email} />);
      },
      grow: 3,
    },
    {
      selector: (row) => row.username,
      cell: (row) => CellBuilder(row.username),
      grow: 1,
    },
    {
      selector: (row) => row.confirmation_status,
      center: true,
      cell: (row) =>
        CellBuilder(
          <BorderedText classes={"Available"} text={row.confirmation_status} />
        ),
      grow: 3,
    },
    {
      grow: 2,
      center: true,
      cell: (row) => (
        <div className="table-actions d-flex justify-content-center align-items-center">
          {row.confirmation_status?.toLowerCase() ===
            "force change password" && (
            <MilerButton
              btnDark
              title="Re-Invite"
              onClick={() => reInvite(row + "sd")}
              targetActionDenied={!hasPermission("reactivate.user")}
            />
          )}
          <ElipsisMenu>
            <MenuItem
              title="Reset Password"
              onClick={() => handlePasswordReset(row)}
              targetActionDenied={!hasPermission("reactivate.user")}
            />
          </ElipsisMenu>
        </div>
      ),
    },
  ];

  return loading ? (
    <div className="col-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
      {loading}
    </div>
  ) : (
    <DataTable
      columns={columns}
      data={data}
      noTableHead
      noHeader
        customStyles={{
          rows: {
            style: {
              background: "#E5E7EB",
              marginBottom:"1rem"
            },
          },
        }}
    />
  );
};

export default ManageLogin;
