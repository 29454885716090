import { BsExclamationTriangle } from "react-icons/bs";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import "./resetPassword.sass";

const ResetSuccessDialog = ({
  open,
  closeModal,
  title,
  text,
  cancelText,
  submitText,
  onSubmit,
  onCancel,
  resetSuccess,
  successLoader,
  setResetSuccess,
}) => {
  return (
    <Modal
      isOpen={Boolean(open)}
      toggle={() => {
        closeModal(false);
        setResetSuccess(false);
      }}
    >
      <ModalHeader toggle={() => closeModal(false)}>{title}</ModalHeader>
      <ModalBody>
        <div>{text}</div>
      </ModalBody>
      {resetSuccess ? (
        <>
          {successLoader ? (
            <div className="mb-2">{successLoader}</div>
          ) : (
            <ModalFooter>
              <div className="slider-display mt-0">
                <div className="dialog-success-text">
                  <div className="text">{resetSuccess}</div>
                  <div className="ico">
                    <BsExclamationTriangle
                      style={{
                        color: "#FA4747",
                        width: "22px",
                        height: "22px",
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </ModalFooter>
          )}
        </>
      ) : (
        <ModalFooter>
          <MilerButton title={cancelText} onClick={onCancel} />
          <MilerButton title={submitText} btnDark onClick={onSubmit} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ResetSuccessDialog;
