import DriverForm from "../../driverForm/DriverForm";

const DriverEdit = ({ state, setState, errors, getDrivers }) => {
  const dispatch = (action) => {
    if (action.category) {
      setState({
        ...state,
        [action.category]: {
          ...state[action.category],
          [action.type]: action.value,
        },
      });
    } else {
      setState({ ...state, [action.type]: action.value });
    }
  };

  return (
    <div className="slider-display">
      <div>
        <DriverForm
          dispatch={dispatch}
          state={state}
          errors={errors}
          fromEdit={true}
          fxn={getDrivers}
        />
      </div>
    </div>
  );
};

export default DriverEdit;
