import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import MilerButton from "components/milerButton/MilerButton";
import { hasPermission } from "utils/hasPermission";

const CustomHeader = ({ name, email, handleRowClick, state, expandedRows }) => (
  <div
    style={{
      marginTop: "4px",
      display: "flex",
      flexDirection: "column",
      gap: "0.3rem",
    }}
  >
    <div>
      {expandedRows && (
        <i
          className={
            expandedRows.length > 0
              ? "mil-chevron-sm-down"
              : "mil-chevron-sm-up"
          }
          style={{
            fontSize: "18px",
            color: "#198BF2",
            cursor: "pointer",
            paddingRight: "4px",
          }}
          onClick={() => handleRowClick(state)}
          title={""}
        />
      )}
      {name}
    </div>
    <div style={{ fontSize: "10px", color: "#626365" }}>{email}</div>
  </div>
);

export const columns = (
  handleRowClick,
  state,
  expandedRows,
  getUsers,
  driverAccessStatus
) => [
  {
    name: "USER",
    selector: (row) => row?.username,
    cell: (row) => {
      const name = row?.firstName + " " + row?.lastName;
      return CellBuilder(
        <CustomHeader
          name={name}
          email={row?.email}
          handleRowClick={handleRowClick}
          state={state}
          expandedRows={expandedRows}
        />
      );
    },
    grow: 3,
  },
  {
    name: "USERNAME",
    selector: (row) => row?.username,
    cell: (row) => CellBuilder(row?.username),
    grow: 1,
  },
  {
    name: "STATUS",
    selector: (row) => row.status,
    center: true,
    cell: (row) =>
      CellBuilder(<BorderedText classes={row?.status} text={row?.status} />),
    grow: 3,
  },
  {
    name: "LOGIN",
    grow: 2,
    center: true,
    cell: (row) => (
      <div className="table-actions d-flex justify-content-center align-items-center">
        <MilerButton
          btnDark
          title={row?.driverAccess ? "Disable" : "Enable"}
          onClick={() => driverAccessStatus(row?.driverAccess)}
          targetActionDenied={!hasPermission("reactivate.user")}
        />

        <MilerButton
          btnDark
          title="Manage"
          onClick={() => {
            handleRowClick(state, true);
            getUsers();
          }}
          targetActionDenied={!hasPermission("reactivate.user")}
        />
      </div>
    ),
  },
];
